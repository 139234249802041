<template>
    <slot />
</template>

<script setup>
    import { Mesh, MeshBasicMaterial } from 'three';
    import { onBeforeUnmount, watch, watchEffect } from 'vue';

    import { simplePlaneGeometry } from '@resn/gozer-three';
    import { useThreeObject, useViewportResize } from '@resn/gozer-vue';

    const props = defineProps({
        alpha: { default: 1 },
        visible: { default: true },
        color: { default: 'red' },
        texture: { default: null },
        useTexture: { default: null },
        layer: { default: 0 },
        renderOrder: { default: 0 },
        blending: { default: null },
        material: { default: null },
        name: { default: 'SimplePlane' },
        bounds: { default: null },
        addToParent: { default: true },
    });

    const color = props.color || null;
    const texture = props.texture || null;
    const material =
        props.material || new MeshBasicMaterial({ color, map: texture, transparent: false });
    if (props.blending) material.blending = props.blending;

    const mesh = new Mesh(simplePlaneGeometry, material);
    useThreeObject(mesh, { name: props.name, addToParent: props.addToParent });

    watchEffect(() => {
        mesh.visible = props.visible;
    });

    watchEffect(() => {
        mesh.material.opacity = props.alpha;
    });

    watchEffect(() => {
        mesh.layers.set(props.layer);
        mesh.renderOrder = props.renderOrder;
    });

    if (props.bounds) {
        watchEffect(() => {
            const { width, height } = props.bounds;
            mesh.scale.set(width, height, 1);
        });
    } else {
        useViewportResize(({ width, height }) => {
            mesh.scale.set(width, height, 1);
        }, true);
    }

    onBeforeUnmount(() => {
        mesh.geometry.dispose();
        mesh.material.dispose();
        texture?.dispose();
    });

    defineExpose({ mesh, material });
</script>

<style lang="scss" scoped></style>
