import { modifySource } from '@resn/gozer-three';

export const setupDefaultTextMaterial = (shader) => {
    // vertex shader
    shader.vertexShader = modifySource(
        shader.vertexShader,
        {
            setup: 'insertafter:attribute vec3 aTroikaGlyphColor;',
            transformed: 'insertafter:vec3 transformed = vec3( troika_position_1 );',
        },
        {
            setup: /* glsl */ `
                attribute vec3 aOffset;
                attribute float aAlpha;
                varying float vAlpha;
                varying vec3 vTransformed;
            `,
            transformed: /* glsl */ `
                transformed += aOffset;
                vAlpha = aAlpha;
                vTransformed = transformed;
            `,
        }
    );

    // fragment shader
    shader.fragmentShader = modifySource(
        shader.fragmentShader,
        {
            setup: 'insertafter:varying vec3 vTroikaGlyphColor;',
            postFragColor: 'insertafter:gl_FragColor.a *= edgeAlpha;',
        },
        {
            setup: /* glsl */ `
                varying float vAlpha;
                varying vec3 vTransformed;
            `,
            postFragColor: /* glsl */ `
                #ifdef USE_MASK
                    float grd = (vTransformed.y + vTroikaGlyphDimensions.y) / vTroikaGlyphDimensions.y;
                    float mask = smoothstep(-0.75, 0., grd );
                    gl_FragColor.a *= mask;
                #endif
                gl_FragColor.a *= vAlpha;   
            `,
        }
    );
};
