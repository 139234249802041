<template>
    <slot />
</template>

<script setup>
    import { InstancedBufferAttribute, Vector3 } from 'three';
    import { Text } from 'troika-three-text';
    import {
        computed,
        onBeforeUnmount,
        onMounted,
        reactive,
        ref,
        shallowRef,
        watch,
        watchEffect,
    } from 'vue';

    import { requestIdleCallback } from '@resn/gozer-misc';
    import { useThreeObject } from '@resn/gozer-vue';

    import { setupDefaultTextMaterial } from './shaders/ShaderChunks/setup-default-text-material';
    import { LAYER_FG } from '~/core/constants';

    const props = defineProps({
        name: { default: 'GLText' },
        visible: { default: true },
        text: { default: 'Text' },
        layer: { default: LAYER_FG },
        renderOrder: { default: 0 },
        material: { default: null },
        uppercase: { default: true },
        useBasesizeRescale: { default: true },
        options: {
            default: {},
        },
    });

    const baseOptions = {
        font: '/fonts/manuka/Manuka-Black.otf',
        fontSize: 60,
        color: 'white',
        textAlign: 'center',
        anchorX: 'center',
        anchorY: 'middle',
        fontWeight: 'normal',
        whiteSpace: 'normal',
        lineHeight: 1,
    };

    const textOptions = computed(() => ({
        ...baseOptions,
        ...props.options,
        text: props.uppercase ? props.text.toUpperCase() : props.text,
    }));
    const baseSize = reactive({ width: 1, height: 1 });

    const scale = computed(
        () => textOptions.value.fontSize / (props.useBasesizeRescale ? baseSize.height : 1)
    );
    const realSize = computed(() => ({
        width: baseSize.width * scale.value,
        height: baseSize.height * scale.value,
    }));
    watch(scale, (val) => {
        textMesh.scale.setScalar(val);
    });

    const { object } = useThreeObject(null, { name: 'GL Text' });

    const textMesh = new Text();
    const textRenderInfo = shallowRef(null);
    const glyphBounds = shallowRef(null);
    const letters = shallowRef([]);
    const count = ref(0);

    object.add(textMesh);
    textMesh.layers.set(props.layer);
    textMesh.material.name = props.name;
    // textMesh.material.depthWrite = true;
    // textMesh.material.depthTest = true;

    // Use custom material if passed in, else update the material with default setup
    if (props.material) textMesh.material = props.material;
    else textMesh.material.onBeforeCompile = setupDefaultTextMaterial;

    const createLetters = () => {
        // Creates an array of letters with their index, pr, alpha, and position
        return new Array(count.value).fill().map((_, index) => {
            return { index, pr: 0, alpha: 1, position: new Vector3() };
        });
    };

    const createAttributes = () => {
        // Creates a position attribute for each letter
        textMesh.geometry.setAttribute(
            'aOffset',
            new InstancedBufferAttribute(new Float32Array(count.value * 3), 3)
        );
        // Creates an alpha attribute for each letter
        textMesh.geometry.setAttribute(
            'aAlpha',
            new InstancedBufferAttribute(new Float32Array(count.value * 1), 1)
        );

        updateLetters();
    };

    const updateLetters = () => {
        if (!letters.value.length) return;

        const { aOffset, aAlpha } = textMesh.geometry.attributes;

        letters.value.forEach((letter, index) => {
            const { position, alpha } = letter;
            const { x, y, z } = position;
            aOffset.setXYZ(index, x, y, z);
            aAlpha.setX(index, alpha);
        });

        aOffset.needsUpdate = true;
        aAlpha.needsUpdate = true;
    };

    const syncText = () => {
        const opts = textOptions.value;
        Object.entries(opts).forEach(([key, value]) => {
            textMesh[key] = value;
        });

        textMesh.sync(onSyncComplete);
    };

    const onSyncComplete = () => {
        textRenderInfo.value = textMesh.textRenderInfo;

        const { blockBounds } = textMesh.textRenderInfo;
        baseSize.width = Math.abs(blockBounds[0] - blockBounds[2]);
        baseSize.height = Math.abs(blockBounds[1] - blockBounds[3]);

        const { aTroikaGlyphBounds, aTroikaGlyphIndex } = textMesh.geometry.attributes;
        glyphBounds.value = aTroikaGlyphBounds;

        count.value = aTroikaGlyphBounds.count;
        letters.value = createLetters();

        createAttributes();

        emit('sync', { aTroikaGlyphBounds, aTroikaGlyphIndex });
    };

    const setProps = () => {
        object.visible = props.visible;
        textMesh.renderOrder = props.renderOrder;
    };

    watch(textOptions, () => {
        requestIdleCallback(syncText);
    });
    watchEffect(setProps);

    onMounted(() => {
        requestIdleCallback(syncText);
    });

    onBeforeUnmount(() => {
        textMesh.geometry.dispose();
        textMesh.material.dispose();
    });

    const emit = defineEmits(['sync']);

    defineExpose({
        object,
        mesh: textMesh,
        baseSize,
        realSize,
        glyphBounds,
        textRenderInfo,
        letters,
        updateLetters,
    });
</script>
